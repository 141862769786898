
































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { Operacao } from '@/core/models/monitoramento';
import { OperacaoService } from '@/core/services/monitoramento';

@Component
export default class ListaOperacao extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions',sortable: false },
    { text: 'Situação', value: 'situacao.nome' },
    { text: 'Agendamento', value: 'agendamento.descricao' },
    { text: 'Inconformidade', value: 'inconformidade' },
  ];
  item = new Operacao();
  service = new OperacaoService();

  filtro: any = {
    transportadoraId: this.app.transportadoraId,
  };
  overlay: boolean = false;

  @Watch('options', { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Situacao, Agendamento.Motorista, Agendamento.Receptor, Agendamento.OrdemDestinacao.Receptor" ).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  mounted(){
  
  }

  AbrirDialogCadastro(item?: Operacao){
    
    this.overlay = true;

    if(item){
      this.service.ObterPorId(item.id, "Fotos.TipoFoto, Perguntas.Pergunta, Situacao, Trajetos, Agendamento.Base, Agendamento.Porto, Agendamento.Receptor, Agendamento.Motorista, Agendamento.OrdemServico.Contrato.Cliente, Agendamento.OrdemServicoInterna.Contrato.Cliente, Agendamento.OrdemDestinacao.Receptor")
      .then(
        res=>{
          this.item = res.data;
          this.dialogCadastro = true;
        },
        err=>{
          this.$swal("Aviso", err.response.data, "error");
        }
      )
      .finally(() => this.overlay = false);
    }
    else{
      this.item = new Operacao();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
      this.overlay = false;
    }
  }

  Excluir(item: Operacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                        return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }
}

